

































import { User } from '@/models';
import { defineComponent, reactive, ref } from '@/plugins/composition';
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import { required, rules } from '@/domains/practitioner/modules/assessments/composables/validations';
import { routeNames } from '@/router/routeNames';
import { storage } from '@/main';
import { useFormValidation } from '@/composables/useFormValidation';
import { useLoader } from '@/composables/useLoader';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'WelcomeForm',
  props: {
    token: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { router, route } = useRouter();
    const user = ref<User | null>(null);

    const valid = ref(false);

    const isPasswordVisible = ref(false);

    const { loading, withLoader } = useLoader();

    const formData = reactive({
      password: '',
      email: props.email,
    });

    const { errors, mapErrors } = useFormValidation(formData);

    const login = async () =>
      withLoader(async () => {
        const reset = await User.resetPassword({
          email: formData.email,
          password: formData.password,
          token: props.token,
        }).catch((error) => mapErrors(error));

        if (reset) {
          const loggedInUser = await User.login(formData).catch((e) => {
            console.log(e);
          });

          if (loggedInUser) {
            user.value = loggedInUser;
            if (user.value) {
              storage.auth.login(user.value);
              if (user.value.data.type === 'practitioner') {
                router.push({ name: routeNames.practitioner.dashboard });
              } else {
                if (route.value.query.redirect) {
                  router.push({ path: route.value.query.redirect as string });
                  return;
                }
                router.push({ name: routeNames.patient.resources });
              }
            }
          }
        }
      });

    return {
      valid,
      formData,
      isPasswordVisible,
      loading,
      login,

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },

      rules,
      required,
      errors,
    };
  },
});
